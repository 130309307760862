import React from 'react'
import './heroServicios.css'



function HeroServicios() {
  return (
    <div className='hero-seccion-servicios d-flex justify-content-center align-items-center'>
      <h1 className='titulo-hero-servicios'>Servicio <br /> Tecnico</h1>
    </div>  
  )
}

export default HeroServicios