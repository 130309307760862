import React from 'react'
import './instagram.css'

function Instagram() {
  return (
    <div className='container text-center'>
      <h1 className='pb-3'>Ultimas publicaciones</h1>
      {/* <script src="https://snapwidget.com/js/snapwidget.js"></script>
      <iframe src="https://snapwidget.com/embed/1022914" className="snapwidget-widget instagram-grid" allowtransparency="true" title='instagram'></iframe>    */}
      <div class="elfsight-app-d60347b4-4342-4eaf-8e45-12a7c4aa5737"></div>
    </div>
  )
}

export default Instagram